import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SEO from '../components/SEO'
import {
  ContentfulFeatureContent,
  ContentfulText,
  JitterbugFlipUpdateQuery,
} from '../../types/graphql-types'
import Layout from '../components/Layout/Layout'

interface Props {
  data: JitterbugFlipUpdateQuery
}

const HeroBackground = styled.section`
  ${tw`max-w-1920 mx-auto md:h-64`}
`
const HeroTextBox = styled.div`
  ${tw`flex content-center flex-wrap h-full`}
  div {
    ${tw`container py-12 text-left text-white`}
    h1 {
      ${tw`leading-none mb-4`}
    }
  }
`

const ContentSection = styled.section`
  ${tw`container pt-8`}

  h2 {
    ${tw`leading-none mb-3 pb-2 md:mb-5`}
  }

  h3 {
    ${tw`mb-3 text-xl`}
  }

  p {
    ${tw`mb-8`}
  }
`

export const query = graphql`
  query JitterbugFlipUpdate {
    contentfulPage(externalName: { eq: "Jitterbug Flip Update" }) {
      title
      externalName
      seoTitle
      seoDescription
      noindex
      references {
        ... on ContentfulFeatureContent {
          id
          name
          externalName
          references {
            ... on ContentfulText {
              id
              name
              externalName
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`

const JitterbugFlipUpdatePage: React.FC<Props> = ({ data }: Props) => (
  <Layout>
    <SEO
      title={data.contentfulPage?.seoTitle as string}
      description={data.contentfulPage?.seoDescription as string}
      noIndex={data.contentfulPage?.noindex as boolean}
    />
    <HeroBackground className="gradient-5-lively">
      <HeroTextBox>
        <div>
          <h1>{data.contentfulPage?.title}</h1>
        </div>
      </HeroTextBox>
    </HeroBackground>
    {data.contentfulPage?.references?.map(content => {
      const featureContent = content as ContentfulFeatureContent
      if (
        featureContent.externalName === 'Jitterbug Flip Update Feature Content'
      ) {
        return (
          <ContentSection key={featureContent.id}>
            {(featureContent.references as ContentfulText[]).map(copy => {
              return (
                <div
                  key={copy.id}
                  className="mb-12"
                  dangerouslySetInnerHTML={{
                    __html: copy?.longSimpleText?.childMarkdownRemark
                      ?.html as string,
                  }}
                ></div>
              )
            })}
          </ContentSection>
        )
      }
    })}
  </Layout>
)

export default JitterbugFlipUpdatePage
